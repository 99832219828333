import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkToc from 'remark-toc'
import rehypeHighlight from 'rehype-highlight'
import moment from 'services/moment'
import withDashboardLayout from 'HOCs/withDashboardLayout'
import { useGetNewsDetails } from 'hooks/api/useNews'
import { config as rehypeHighlightConfig } from 'config/rehypeHighlightConfig'
import { articleMarkdown } from 'config/markdownConfig'
import ErrorBoundary from './ErrorBoundary'
import { createHeadingComponent, customLinkComponent } from './markdownComponents'

function NewsDetails() {
  const [error, setError] = useState(null)
  const { slug } = useParams()
  const contentRef = useRef(null)

  const { data } = useGetNewsDetails(slug)
  const { meta, title, content, created_at: createdAt } = data ?? {}
  const date = moment.toDefaultFormat(createdAt)

  useEffect(() => {
    if (data && content && contentRef.current) {
      try {
        renderMarkdown()
        handleAnchorLink()
      } catch (err) {
        setError(err.message)
      }
    }
  }, [data, content])

  const renderMarkdown = () => {
    ReactMarkdown({
      children: content,
      components: customComponents,
      remarkPlugins: [[remarkToc, { tight: true, ordered: true }]],
      rehypePlugins: [[rehypeHighlight, rehypeHighlightConfig]]
    })
  }

  const handleAnchorLink = () => {
    const hash = window.location.hash.slice(1)
    if (hash) {
      const observer = new MutationObserver(() => {
        const element = document.getElementById(hash)
        if (element) {
          observer.disconnect()
          scrollToElement(element)
        }
      })
      observer.observe(contentRef.current, { childList: true, subtree: true })
    }
  }

  const scrollToElement = (element) => {
    const headerHeight = document.querySelector('header').offsetHeight
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - headerHeight
    
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }

  const customComponents = {
    ...articleMarkdown,
    a: customLinkComponent(scrollToElement),
    h1: createHeadingComponent(1),
    h2: createHeadingComponent(2),
    h3: createHeadingComponent(3),
  }

  if (error) {
    return <div>Error rendering markdown: {error}</div>
  }

  return (
    <>
      {data && (
        <section className="article-details">
          <div className="article-details__container container">
            <div className="article-details__title title">
              <h1>{meta?.title || title}</h1>
            </div>
            <p className="article-details__short-desc">{meta?.description}</p>
            <time className="article-details__public-date public-date">{date}</time>
            <div className="article-desc" ref={contentRef}>
              <ReactMarkdown
                components={customComponents}
                remarkPlugins={[[remarkToc, { tight: true, ordered: true }]]}
                rehypePlugins={[[rehypeHighlight, rehypeHighlightConfig]]}
              >
                {content}
              </ReactMarkdown>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default withDashboardLayout()((props) => (
  <ErrorBoundary>
    <NewsDetails {...props} />
  </ErrorBoundary>
))
