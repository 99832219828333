import React from 'react'

export const createHeadingComponent = (level) => ({node, children, ...props}) => {
  let id, content
  if (typeof children[0] === 'string') {
    const match = children[0].match(/\{#([^}]+)\}$/)
    id = match ? match[1] : children[0].replace(/\s+/g, '-').toLowerCase()
    content = match ? children[0].replace(/\{#[^}]+\}$/, '') : children[0]
  } else {
    id = props.id || ''
    content = children
  }
  const HeadingTag = `h${level}`
  return <HeadingTag id={id} {...props}><a href={`#${id}`} className="anchor">{content}</a></HeadingTag>
}

export const customLinkComponent = (scrollToElement) => ({node, ...props}) => {
  if (props.href && props.href.startsWith('#')) {
    return <a {...props} onClick={(e) => {
      e.preventDefault()
      const element = document.getElementById(props.href.slice(1))
      if (element) {
        scrollToElement(element)
      }
    }} />
  }
  return <a target="_blank" rel="noopener noreferrer" {...props} />
}